<template>
<svg
    width="25px"
    height="25px"
    fill="currentColor"
    viewBox="-2 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m17.428 6.572c-.201-.219-.488-.355-.807-.355-.604 0-1.094.49-1.094 1.094 0 .319.136.606.354.806l.001.001c1.455 1.452 2.355 3.46 2.355 5.678 0 4.431-3.592 8.024-8.024 8.024s-8.024-3.592-8.024-8.024c0-2.456 1.103-4.654 2.841-6.126l.012-.01.515 2.586c.104.505.545.88 1.073.88.377 0 .71-.191.907-.482l.002-.004 3.577-5.354v-.004l.006-.01c.017-.027.034-.053.049-.082l.007-.014c.014-.027.027-.055.04-.086l.005-.015c.011-.028.021-.056.03-.086 0-.007 0-.014.005-.02.008-.027.015-.055.021-.086 0-.013 0-.027.006-.04s.008-.044.009-.066c0-.036.005-.072.005-.109s0-.008 0-.012c0-.032 0-.064-.005-.096s-.007-.05-.011-.075c0-.01 0-.02-.004-.03-.006-.033-.015-.064-.024-.095v-.009c-.01-.033-.021-.065-.034-.096v-.004c-.013-.03-.027-.059-.041-.086l-.004-.009c-.014-.026-.029-.051-.045-.076l-.01-.016c-.015-.021-.03-.042-.046-.062l-.018-.024c-.015-.019-.033-.037-.05-.055l-.021-.024q-.038-.038-.078-.071l-.015-.011c-.023-.019-.047-.037-.071-.053l-5.362-3.58c-.171-.115-.381-.184-.608-.184-.604 0-1.094.49-1.094 1.094 0 .075.008.149.022.22l-.001-.007.791 3.978c-2.767 1.855-4.564 4.97-4.564 8.505 0 5.637 4.57 10.207 10.207 10.207s10.207-4.57 10.207-10.207c0-2.818-1.142-5.37-2.989-7.217z"></path></g></svg>

</template>
