<template>
  <svg
    width="24px"
    height="24px"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000"
    fill="currentColor"
  >
  <g><path d="M447.6,676.7c6.4,23.6-3.8,54-22.3,72.6L325.5,849c-27.5,27.5-72.2,27.5-99.7,0L151,774.2c-27.5-27.5-27.5-72.2,0-99.7l99.7-99.7c19.5-19.5,47.6-29.4,72.2-21.2l77.4-78.5c-55.1-55.1-144.4-55.1-199.4,0L51.3,624.6c-55.1,55.1-55.1,144.4,0,199.4l124.6,124.6c55.1,55.1,144.4,55.1,199.4,0l149.6-149.6c55.1-55.1,55.1-144.4,0-199.4L447.6,676.7z M948.7,175.9L824.1,51.3C769-3.8,679.7-3.8,624.6,51.3L475.1,200.9c-55.1,55.1-55.1,144.4,0,199.4l78.5-77.4c-8.2-24.5,1.7-52.6,21.2-72.2l99.7-99.7c27.5-27.5,72.2-27.5,99.7,0l74.8,74.8c27.5,27.5,27.5,72.2,0,99.7l-99.7,99.7c-18.5,18.5-49,28.8-72.6,22.3l-77,77.4c55.1,55.1,144.4,55.1,199.4,0l149.6-149.6C1003.8,320.3,1003.8,231,948.7,175.9z M338,662c20.6,20.7,54.1,20.7,74.8,0L662,412.8c20.7-20.7,20.7-54.1,0-74.8c-20.7-20.7-54.1-20.7-74.8,0L338,587.2C317.3,607.9,317.3,641.4,338,662z"/></g>
  </svg>
</template>
