<template>
  <ContainerPage>
    <main>
      <NoteShow
        v-bind="{ noteId: note.id, storageAccessor, expandChildren: false }"
      />
    </main>
    <NoteInfoBar
      :note-id="note.id"
      :key="note.id"
      @level-changed="$emit('reloadNeeded', $event)"
    />
    <AssimilationButtons
      :key="buttonKey"
      @assimilate="processForm"
    />
  </ContainerPage>
</template>

<script setup lang="ts">
import type { Note, MemoryTracker } from "@/generated/backend"
import useLoadingApi from "@/managedApi/useLoadingApi"
import ContainerPage from "@/pages/commons/ContainerPage.vue"
import type { StorageAccessor } from "@/store/createNoteStorage"
import usePopups from "../commons/Popups/usePopups"
import NoteInfoBar from "../notes/NoteInfoBar.vue"
import AssimilationButtons from "./AssimilationButtons.vue"
import NoteShow from "../notes/NoteShow.vue"
import { computed } from "vue"
import { useRecallData } from "@/composables/useRecallData"

// Props
const { note } = defineProps<{
  note: Note
  storageAccessor: StorageAccessor
}>()

// Emits
const emit = defineEmits<{
  (e: "reloadNeeded", data: MemoryTracker): void
  (e: "initialReviewDone", data: MemoryTracker): void
}>()

// Composables
const { managedApi } = useLoadingApi()
const { popups } = usePopups()
const { totalAssimilatedCount } = useRecallData()

// Computed
const buttonKey = computed(() => note.id)

// Methods
const processForm = async (skipMemoryTracking: boolean) => {
  if (skipMemoryTracking) {
    const confirmed = await popups.confirm(
      "Confirm to hide this note from reviewing in the future?"
    )
    if (!confirmed) {
      return
    }
  }

  const data = await managedApi.assimilationController.assimilate({
    noteId: note.id,
    skipMemoryTracking,
  })

  if (totalAssimilatedCount.value !== undefined) {
    totalAssimilatedCount.value += 1
  }

  if (skipMemoryTracking) {
    emit("reloadNeeded", data)
  } else {
    emit("initialReviewDone", data)
  }
}
</script>

<style>
.initial-review-paused {
  background-color: rgba(50, 50, 150, 0.8);
  padding: 5px;
  border-radius: 10px;
}
</style>
