<template>
  <svg
    width="25px"
    height="25px"
    x="0px"
    y="0px"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 100 100"
    enable-background="new 0 0 100 100"
    xml:space="preserve"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M22,71.8h56c1.1,0,2,0.9,2,2v4c0,1.1-0.9,2-2,2H22c-1.1,0-2-0.9-2-2v-4C20,72.7,20.9,71.8,22,71.8z"
      ></path>
      <path
        d="M22,53.8h56c1.1,0,2,0.9,2,2v4c0,1.1-0.9,2-2,2H22c-1.1,0-2-0.9-2-2v-4C20,54.7,20.9,53.8,22,53.8z"
      ></path>
      <path
        d="M57.6,35.8H78c1.1,0,2,0.9,2,2v4c0,1.1-0.9,2-2,2H57.6c-1.1,0-2-0.9-2-2v-4C55.6,36.7,56.5,35.8,57.6,35.8z"
      ></path>
      <path
        d="M47.6,44.4L38.1,21c-0.2-0.4-0.6-0.7-1.1-0.7h-7.2c-0.4,0-0.9,0.3-1,0.7L20,44.4c-0.1,0.4,0.1,1,0.7,1h4.6 c0.4,0,0.9-0.4,1-0.8l1.8-5l11.1,0l2,5c0.1,0.4,0.6,0.8,1,0.8h4.6C47.4,45.4,47.7,44.9,47.6,44.4z M30.2,33.6l2.9-7.4h0.6l3.2,7.4 H30.2z"
      ></path>
    </g>
  </svg>
</template>
